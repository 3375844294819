<template>
  <div class="page-about-you animate__animated animate__fadeIn pt-4">
    <!-- <img :src="require('@/assets/banners/grl-info-1.png')" class="w-100 mb-4" alt="" /> -->
    <video
      v-if="!isMobile()"
      :src="`${urlBucket}/form/assets/animation-about-you.mp4`"
      class="img-fluid mb-4"
      style="border-radius: 120px; display: none"
      autoplay
      muted
      loop
      @loadeddata="
        (e) => {
          e.target.style.display = 'unset';
          hideSpinner('animation');
        }
      "
    ></video>

    <img
      v-else
      :src="`${urlBucket}/form/assets/banner-about-you.png`"
      class="w-100 mb-4"
      @load="
        (e) => {
          e.target.style.display = 'unset';
          hideSpinner('animation');
        }
      "
      style="border-radius: 120px; display: none"
      alt=""
    />

    <div id="animation" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>
    <!-- Title -->
    <div class="mb-4">
      <h5 class="fs-la">Title</h5>
      <vSelect
        :value="title"
        class="w-100 select-title fs-md mb-2"
        label="title"
        :clearable="false"
        :options="titles"
        :placeholder="`Title`"
        :multiple="false"
        @input="(e) => selectedChange('title', e)"
      >
      </vSelect>
      <p class="mb-0 fs-md" v-if="errors.title" style="color: #c64a45">* This field is required</p>
    </div>

    <!-- First Name -->
    <div class="mb-4">
      <h5 class="fs-la">First Name</h5>
      <input
        type="text"
        placeholder="First Name"
        class="w-100 p-3 fs-md mb-2"
        :value="firstName"
        @input="(e) => selectedChange('firstName', e.target.value)"
      />
      <p class="mb-0 fs-md" v-if="errors.firstName" style="color: #c64a45">
        * This field is required
      </p>
    </div>

    <!-- Last Name -->
    <div class="mb-4">
      <h5 class="fs-la">Last Name</h5>
      <input
        type="text"
        placeholder="Last Name"
        class="w-100 p-3 fs-md mb-2"
        :value="lastName"
        @input="(e) => selectedChange('lastName', e.target.value)"
      />
      <p class="mb-0 fs-md" v-if="errors.lastName" style="color: #c64a45">
        * This field is required
      </p>
    </div>

    <p class="mb-2 fs-md" v-if="errors.existsUnderDifferentName" style="color: #c64a45">
      * The email address is currently being used for a different full name. Please use a different
      email address to continue
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import { titles } from "../data/lists";
// import MainButton from "../components/MainButton";
import { navigateTo } from "../router/index";
import { isMobile } from "../utils/isMobile";
// @ is an alias to /src

export default {
  name: "PageAboutYou",
  components: { Datepicker, vSelect },
  data() {
    return {
      titles,
      isMobile,
      errors: {
        title: false,
        firstName: false,
        lastName: false,
        existsUnderDifferentName: false,
      },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    validateNINO() {
      return /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1}?\s*$/.test(this.selectedNINO);
    },
    validateInputs() {
      this.errors.title = this.title == "" ? true : false;
      this.errors.firstName = this.firstName == "" ? true : false;
      this.errors.lastName = this.lastName == "" ? true : false;
    },
    openRegisterElectoralRoll(answer) {
      if (answer == "yes") {
        this.$store.commit("formStore/setField", [`selectedOpenRegister`, "yes"]);
      }

      if (answer == "no") {
        this.$store.commit("formStore/setField", [`selectedOpenRegister`, "no"]);
      }
    },
    inputChange(itemName, value) {
      // console.log("inputChange", value);
      this.$store.commit("formStore/setField", [`${itemName}`, value.toUpperCase()]);
    },
    selectedChange(itemName, value) {
      // console.log("selectedChange", value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    toggleCheck(e) {
      this.$store.commit("formStore/setField", [`moveOutFlag`, e]);
    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "A little about you"]);
    this.$store.commit("setField", ["step4", true]);

    const fetchExistsUnderDifferentName = () => {
      return fetch(
        `${this.baseURL}/user/utils/existsUnderDifferentName?email=${this.email}&firstName=${this.firstName}&lastName=${this.lastName}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        return res.json();
      });
    };



    const validatePage = async () => {

      if (this.errors.existsUnderDifferentName) {
        navigateTo("/");
        return
      }

      let route = "/review";

      const setRoute = () => {
        /*if (
          this.showInputContactNumber ||
          !this.iKnowMyMoveInDate ||
          this.showInputMoveInDate ||
          this.informElectoralRoll
        ) {
          route = "/about-you-2";
          return;
        }*/

        if (this.moneySaved < 20) {
          route = "/disclaimer";
          return;
        }
      };

     // setRoute();

      this.validateInputs();

      await fetchExistsUnderDifferentName().then((exists) => {
        if (exists) {
          this.errors.existsUnderDifferentName = true;
          this.$store.commit("setField", [`messageButton`, "Go Back"]);
        } else {
          this.errors.existsUnderDifferentName = false;
        }
      });

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(route);
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },
  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "email",
      "title",
      "firstName",
      "lastName",
      "moveInDate",
      "moveOutDate",
      "moveOutFlag",
      "informElectoralRoll",
      "informMobile",
      "informLoyalty",
      "selectedOpenRegister",
      "informNHS",
      "informDentist",
      "informPension",
      "informCouncilTax",
      "informWaterSewerage",
      "informStudentFinance",
      "informDVLA",
      "informHMRC",
      "informDWP",
      "informPension",
      "informCharities",
      "informGyms",
      "informOpticians",
      "contactNumber",
      "selectedNINO",
      "showInputContactNumber",
      "iKnowMyMoveInDate",
      "showInputMoveInDate",
      "timeSaved",
      "moneySaved",
    ]),
  },
};
</script>
<style lang="scss">
.page-about-you {
  .select-title {
    .vs__selected {
      color: black;
    }
    .vs__dropdown-toggle {
      padding: 0.8rem !important;
      border-radius: 0.375rem;
      color: black;
    }

    .vs__dropdown-menu {
      color: black !important;
    }
  }

  .input-ninu {
    font-size: 25px;
    padding: 10px;
    padding-right: 40px;
  }

  .icon-security {
    float: right;
    margin-right: 12px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
  }

  .box-answers {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 14px 34px;
    cursor: pointer;
  }

  .checked {
    border: 1px solid #2ec989;
    background-color: #1e9d68;
    color: white;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .vdp-datepicker {
    color: black;
    input {
      padding: 1rem !important;
      width: 100% !important;
    }
  }
}
</style>
