const councils = [
  "Aberdeen",
  "Aberdeenshire",
  "Adur",
  "Allerdale",
  "Amber Valley",
  "Angus",
  "Antrim and Newtownabbey",
  "Ards and North Down",
  "Argyll and Bute",
  "Armagh City, Banbridge and Craigavon",
  "Arun",
  "Ashfield",
  "Ashford",
  "Aylesbury Vale",
  "Babergh",
  "Barnsley Metropolitan",
  "Barrow-in-Furness",
  "Basildon",
  "Basingstoke and Deane",
  "Bassetlaw",
  "Bath and North East Somerset",
  "Bedford",
  "Belfast",
  "Birmingham",
  "Blaby",
  "Blackburn with Darwen",
  "Blackpool",
  "Blaenau Gwent County",
  "Bolsover",
  "Bolton Metropolitan",
  "Borough of Broxbourne",
  "Boston",
  "Bournemouth, Christchurch and Poole",
  "Bracknell Forest",
  "Bradford Metropolitan",
  "Braintree",
  "Breckland",
  "Brentwood",
  "Bridgend County",
  "Brighton and Hove",
  "Bristol",
  "Broadland",
  "Bromsgrove",
  "Broxtowe",
  "Burnley",
  "Bury Metropolitan",
  "Caerphilly",
  "Calderdale",
  "Cambridge",
  "Cannock Chase",
  "Canterbury",
  "Cardiff",
  "Carlisle",
  "Carmarthenshire",
  "Castle Point",
  "Causeway Coast and Glens",
  "Central Bedfordshire",
  "Ceredigion",
  "Charnwood",
  "Chelmsford",
  "Cheltenham",
  "Cherwell",
  "Cheshire East",
  "Cheshire West and Chester",
  "Chesterfield",
  "Chichester",
  "Chiltern",
  "Chorley",
  "City of Lincoln",
  "City of London",
  "City of York",
  "Clackmannanshire",
  "Colchester",
  "Comhairle nan Eilean Siar",
  "Conwy",
  "Copeland",
  "Corby",
  "Cornwall (Unitary)",
  "Cotswold",
  "Coventry",
  "Craven",
  "Crawley",
  "Dacorum",
  "Darlington",
  "Dartford",
  "Daventry",
  "Denbighshire",
  "Derby",
  "Derbyshire Dales",
  "Derry City and Strabane",
  "Doncaster",
  "Dorset",
  "Dover",
  "Dudley",
  "Dumfries and Galloway",
  "Dundee City",
  "Durham",
  "East Ayrshire",
  "East Cambridgeshire",
  "East Devon",
  "East Dunbartonshire",
  "East Hampshire",
  "East Hertfordshire",
  "East Lindsey",
  "East Lothian",
  "East Northamptonshire",
  "East Renfrewshire",
  "East Riding of Yorkshire",
  "East Staffordshire",
  "East Suffolk",
  "Eastbourne",
  "Eastleigh",
  "Eden",
  "Edinburgh",
  "Elmbridge",
  "Epping Forest",
  "Epsom and Ewell",
  "Erewash",
  "Exeter",
  "Falkirk",
  "Fareham",
  "Fenland",
  "Fermanagh and Omagh",
  "Fife",
  "Flintshire",
  "Folkestone and Hythe",
  "Forest of Dean",
  "Fylde",
  "Gateshead",
  "Gedling",
  "Glasgow City",
  "Gloucester",
  "Gosport",
  "Gravesham",
  "Great Yarmouth",
  "Guildford",
  "Gwynedd",
  "Halton",
  "Hambleton",
  "Harborough",
  "Harlow",
  "Harrogate",
  "Hart",
  "Hartlepool",
  "Hastings",
  "Havant",
  "Herefordshire",
  "Hertsmere",
  "High Peak",
  "Highland",
  "Hinckley and Bosworth",
  "Horsham",
  "Huntingdonshire",
  "Hyndburn",
  "Inverclyde",
  "Ipswich",
  "Isle of Anglesey",
  "Isle of Wight",
  "Isles of Scilly",
  "Kettering",
  "King's Lynn and West Norfolk",
  "Kingston-upon-Hull",
  "Kirklees",
  "Knowsley",
  "Lancaster",
  "Leeds",
  "Leicester",
  "Lewes",
  "Lichfield",
  "Lisburn and Castlereagh",
  "Liverpool",
  "London of Barking and Dagenham",
  "London of Barnet",
  "London of Bexley",
  "London of Brent",
  "London of Bromley",
  "London of Camden",
  "London of Croydon",
  "London of Ealing",
  "London of Enfield",
  "London of Hackney",
  "London of Hammersmith & Fulham",
  "London of Haringey",
  "London of Harrow",
  "London of Havering",
  "London of Hillingdon",
  "London of Hounslow",
  "London of Islington",
  "London of Lambeth",
  "London of Lewisham",
  "London of Merton",
  "London of Newham",
  "London of Redbridge",
  "London of Richmond upon Thames",
  "London of Southwark",
  "London of Sutton",
  "London of Tower Hamlets",
  "London of Waltham Forest",
  "London of Wandsworth",
  "Luton",
  "Maidstone",
  "Maldon",
  "Malvern Hills",
  "Manchester",
  "Mansfield",
  "Medway",
  "Melton",
  "Mendip",
  "Merthyr Tydfil",
  "Mid and East Antrim",
  "Mid Devon",
  "Mid Suffolk",
  "Mid Sussex",
  "Mid Ulster",
  "Middlesbrough",
  "Midlothian",
  "Milton Keynes",
  "Mole Valley",
  "Monmouthshire",
  "Moray",
  "Neath Port Talbot",
  "New Forest",
  "Newark and Sherwood",
  "Newcastle-Under-Lyme",
  "Newcastle-upon-Tyn",
  "Newpor",
  "Newry, Mourne and Down",
  "North Ayrshire",
  "North Devon",
  "North East Derbyshire",
  "North East Lincolnshire",
  "North Hertfordshire",
  "North Kesteven",
  "North Lanarkshire",
  "North Lincolnshire",
  "North Norfolk",
  "North Somerset",
  "North Tyneside",
  "North Warwickshire",
  "North West Leicestershire",
  "Northampton",
  "Northumberland",
  "Norwich",
  "Nottingham",
  "Nuneaton and Bedworth",
  "Oadby and Wigston",
  "Oldham",
  "Orkney Islands",
  "Oxford",
  "Pembrokeshire",
  "Pendle",
  "Perth and Kinross",
  "Peterborough",
  "Plymouth",
  "Portsmouth",
  "Powys",
  "Preston",
  "Reading",
  "Redcar and Cleveland",
  "Redditch",
  "Reigate & Banstead",
  "Renfrewshire",
  "Rhondda Cynon Taf",
  "Ribble Valley",
  "Richmondshire",
  "Rochdale Metropolitan",
  "Rochford",
  "Rossendale",
  "Rother",
  "Rotherham",
  "Royal of Greenwich",
  "Royal of Kensington and Chelsea",
  "Royal of Kingston upon Thames",
  "Royal of Windsor and Maidenhead",
  "Rugby",
  "Runnymede",
  "Rushcliffe",
  "Rushmoor",
  "Rutland",
  "Ryedale",
  "Salford",
  "Sandwell",
  "Scambridge",
  "Scarborough",
  "Scottish Borders",
  "Sedgemoor",
  "Sefton",
  "Selby",
  "Sevenoaks",
  "Sheffield",
  "Shetland Islands",
  "Shropshire",
  "Slough",
  "Solihull",
  "Somerset West and Taunton",
  "South Ayrshire",
  "South Buckinghamshire",
  "Buckinghamshire",
  "South Cambridgeshire",
  "South Derbyshire",
  "South Gloucestershire",
  "South Hams",
  "South Holland",
  "South Kesteven",
  "South Lakeland",
  "South Lanarkshire",
  "South Norfolk",
  "South Northamptonshire",
  "South Oxfordshire",
  "South Ribble",
  "South Somerset",
  "South Staffordshire",
  "South Tyneside",
  "Southampton",
  "Southend-on-Sea",
  "Spelthorne",
  "St Albans City",
  "St Helens",
  "Stafford",
  "Staffordshire Moorlands",
  "Stevenage",
  "Stirling",
  "Stockport",
  "Stockton-on-Tees",
  "Stoke-on-Trent",
  "Stratford-on-Avon",
  "Stroud",
  "Sunderland",
  "Surrey Heath",
  "Swale",
  "Swansea",
  "Swindon",
  "Tameside",
  "Tamworth",
  "Tandridge",
  "Teignbridge",
  "Telford & Wrekin",
  "Tendring",
  "Test Valley",
  "Tewkesbury",
  "Thanet",
  "Three Rivers",
  "Thurrock",
  "Tonbridge and Malling",
  "Torbay",
  "Torfaen",
  "Torridge",
  "Trafford",
  "Tunbridge Wells",
  "Uttlesford",
  "Vale of Glamorgan",
  "Vale of White Horse",
  "Wakefield",
  "Walsall",
  "Warrington",
  "Warwick",
  "Watford",
  "Waverley",
  "Wiltshire",
  "Winchester",
  "Wirral",
  "Woking",
  "Wokingham",
  "Wolverhampton",
  "Worcester",
  "Worthing",
  "Wrexham",
  "Wychavon",
  "Wycombe",
  "Wyre",
  "Wyre Forest",
];

let waterCompanies = [
  "Anglian Water",
  "Northern Ireland Water",
  "Dwr Cymru Welsh Water",
  "Northumbrian Water",
  "Severn Trent",
  "South West Water",
  "Southern Water",
  "Thames Water",
  "United Utilities",
  "Wessex Water",
  "Yorkshire Water",
  "Affinity Water",
  "Bournemouth Water",
  "Bristol Water",
  "Cambridge Water",
  "Essex & Suffolk Water",
  "Portsmouth Water",
  "South East Water",
  "South Staffordshire Water",
  "SES Water",
];

const loyalties = [
  { name: "Boots", icon: "icons/Boots.png" },
  { name: "Costco", icon: "icons/costco.png" },
  { name: "H&B", icon: "icons/hb.png" },
 // { name: "Iceland", icon: "icons/iceland.png" },
  { name: "Ikea", icon: "icons/ikea.png" },
  { name: "John Lewis", icon: "icons/John Lewis.png" },
  //{ name: "Lidl", icon: "icons/Lidl.png" },
  // { name: "Matalan", icon: "icons/matalan.png" },
  // { name: "Paperchase", icon: "icons/paperchase.png" },
  { name: "Tesco", icon: "icons/tesco.png" },
  //  {name: 'TKMAXX', icon:'icons/tkmaxx.png'},
  { name: "Waitrose", icon: "icons/waitrose.png" },
];

const energySuppliers = [
  //{ name: "Boost Energy", icon: "icons/boost.png" },
  { name: "British Gas", icon: "icons/britishgas.png" },
  { name: "Budget Energy", icon: "icons/budgetenergy.png" },
  { name: "Bulb Energy", icon: "icons/bulb.png" },
  { name: "Click Energy", icon: "icons/clickenergy.png" },
  { name: "Co-Operative Energy", icon: "icons/coopenergy.png" },
  { name: "E.ON", icon: "icons/eon.png" },
  { name: "E.ON Next", icon: "icons/eonnext.png" },
  { name: "EBICO", icon: "icons/ebico.png" },
  { name: "Ecotricity", icon: "icons/ecotricity.png" },
  { name: "EDF Energy", icon: "icons/edfenergy.png" },
  { name: "Electric Ireland", icon: "icons/electricireland.png" },
  { name: "ESB Energy", icon: "icons/esb.png" },
  { name: "Future Energy", icon: "icons/futureenergy.png" },
  { name: "Good Energy", icon: "icons/goodenergy.png" },
  { name: "Green Energy UK", icon: "icons/greennetwork.png" },
  { name: "Green.Energy", icon: "icons/green.png" },
  { name: "M&S Energy", icon: "icons/msenergy.png" },
  { name: "Nabuh", icon: "icons/nabuh.png" },
  { name: "National Gas", icon: "icons/nationalgas.png" },
  { name: "Octopus Energy", icon: "icons/octopus.png" },
  { name: "Out Fox the Market", icon: "icons/outfox.png" },
  { name: "Ovo Energy", icon: "icons/pvc.png" },
  { name: "Power NI", icon: "icons/powerni.png" },
  { name: "Qwest", icon: "icons/qwest.png" },
  { name: "Sainsbury's Energy", icon: "icons/sainsburyse.png" },
  { name: "Scottish Power", icon: "icons/scottishpower.png" },
  { name: "Shell Energy", icon: "icons/shellener.png" },
  { name: "SSE", icon: "icons/sse.png" },
  { name: "Utilita", icon: "icons/utilitypoint.png" },
  { name: "Utility Warehouse", icon: "icons/uw.png" },
];
const dentists = [""];

const mobileProviders = [
  { name: "Asda Mobile", icon: "icons/mobile/asdamobile.jpg" },
  { name: "BT Mobile", icon: "icons/mobile/btmobile.png" },
  { name: "CMLink", icon: "icons/mobile/cmlilnk.png" },
  { name: "Ecotalk", icon: "icons/mobile/ecotalk.jpg" },
  { name: "EE", icon: "icons/mobile/ee.png" },
  { name: "Extreme Connect", icon: "icons/mobile/truphone.png" },
  { name: "giffgaff", icon: "icons/mobile/giffgaff.png" },
  { name: "iD Mobile", icon: "icons/mobile/idmobile.png" },
  { name: "Lebara Mobile", icon: "icons/mobile/lebara.png" },
  { name: "Lycamobile", icon: "icons/mobile/lycamobile.jpg" },
  { name: "Now", icon: "icons/mobile/nowMobile.png" },
  // { name: "O2", icon: "icons/mobile/o2_transparent.png" },
  { name: "Plusnet Mobile", icon: "icons/mobile/plusNet.jpg" },
  { name: "Sky Mobile", icon: "icons/mobile/skymobile.jpg" },
  { name: "SMARTY", icon: "icons/mobile/smarty.png" },
  { name: "Superdrug Mobile", icon: "icons/mobile/superdrugMobile.png" },
  { name: "Talkmobile", icon: "icons/mobile/talkmobile.png" },
  { name: "TalkTalk Mobile", icon: "icons/mobile/talktalk.jpg" },
  { name: "Tesco Mobile", icon: "icons/mobile/tescoMobile.png" },
  { name: "The Phone Co-op", icon: "icons/mobile/phoneco-op.png" },
  { name: "Three", icon: "icons/mobile/three.png" },
  { name: "Truphone", icon: "icons/mobile/truphone.png" },
  { name: "Utility Warehouse-Telecom Plus", icon: "icons/mobile/UWTP.jpg" },
  { name: "Virgin Mobile", icon: "icons/mobile/virginmobile.png" },
  { name: "VOXI", icon: "icons/mobile/voxi.png" },
  { name: "Vodafone", icon: "icons/mobile/vodafone.png" },
];
const charities = [
  { name: "ActionAid", icon: "icons/actionaid.png" },
  { name: "Alzheimer's Society", icon: "icons/alzheimer.png" },
  { name: "Amnesty International", icon: "icons/amnesty.png" },
  { name: "Barnardos", icon: "icons/Barnardos.png" },
  { name: "Book Trust", icon: "icons/booktrust.png" },
  { name: "British Heart Foundation", icon: "icons/britishheart.png" },
  { name: "Campaign for Nuclear Disarmament", icon: "icons/nuclear.png" },
  { name: "Cancer Research UK", icon: "icons/cancerresearch.png" },
  { name: "Diabetes UK", icon: "icons/diabetes.png" },
  { name: "Dignity in Dying", icon: "icons/dignity.png" },
  { name: "Dogs Trust", icon: "icons/dogstrust.png" },
  { name: "Duke of Edinburgh Awards", icon: "icons/duke.png" },
  { name: "Friends of the Earth", icon: "icons/friendsearth.png" },
  { name: "Great Ormond Street Hospital", icon: "icons/ormond.png" },
  { name: "Kidney Research UK", icon: "icons/kidneyresearch.png" },
  { name: "London's Air Ambulance", icon: "icons/londonambulance.png" },
  { name: "Marie Curie", icon: "icons/mariecurie.png" },
  { name: "Medecins Sans Frontieres", icon: "icons/medics.png" },
  { name: "Mind", icon: "icons/mind.png" },
  { name: "Movember", icon: "icons/movember.png" },
  { name: "National Trust", icon: "icons/nationaltrust.png" },
  { name: "National Trust for Scotland", icon: "icons/nationaltrustscot.png" },
  { name: "Oxfam", icon: "icons/oxfam.png" },
  { name: "PETA", icon: "icons/peta.png" },
  { name: "Prostate Cancer UK", icon: "icons/prostate.png" },
  { name: "Samaritans", icon: "icons/samaritans.png" },
  { name: "Shelter", icon: "icons/shelter.png" },
  { name: "Sightsavers", icon: "icons/sightsavers.png" },
  { name: "The Blue Cross", icon: "icons/bluecross.png" },
  { name: "The Children's Society", icon: "icons/childrensociety.png" },
  { name: "The Fire Fighters Charity", icon: "icons/firefighters.png" },
  { name: "WaterAid", icon: "icons/wateraid.png" },
  { name: "UNICEF", icon: "icons/unicef.png" },
  { name: "WWF", icon: "icons/wwf.png" },
  { name: "Action on Smoking and Health", icon: "icons/Action-Smoking-Health.png" },
  { name: "Arthiritis Research UK", icon: "icons/Arthiritis.png" },
  { name: "Bowel Cancer UK", icon: "icons/Bowel.png" },
  { name: "Breast Cancer Now", icon: "icons/Breast-Cancer-Now.png" },
  { name: "Breast Cancer Care", icon: "icons/Breast-Cancer-Care.png" },
  { name: "CAFOD", icon: "icons/CAFOD.png" },
  { name: "British Red Cross", icon: "icons/British-Red-Cross.png" },
  { name: "Cats Protection", icon: "icons/Cats-Protection.png" },
  { name: "Centrepoint", icon: "icons/Centrepoint.png" },
  { name: "Children in Need", icon: "icons/Children-in-Need.png" },
  { name: "Children with Cancer", icon: "icons/Children-with-Cancer.png" },
  { name: "Christian Aid", icon: "icons/Christian-Aid.png" },
  { name: "CLIC Sargent", icon: "icons/CLIC-Sargent.png" },
  { name: "GambleAware", icon: "icons/GambleAware.png" },
  { name: "Greenpeace", icon: "icons/Greenpeace.png" },
  { name: "Guide Dogs", icon: "icons/Guide Dogs.png" },
  { name: "Hearing Dogs", icon: "icons/Hearing Dogs.png" },
  { name: "Human Appeal", icon: "icons/Human Appeal.png" },
  { name: "LGBT Foundation", icon: "icons/LGBT Foundation.png" },
  { name: "Macmillan Cancer Support", icon: "icons/Macmillan Cancer Support.png" },
  { name: "Mencap", icon: "icons/Mencap.png" },
  { name: "Midlands Air Ambulance", icon: "icons/Midlands Air Ambulance.png" },
  { name: "MS Society", icon: "icons/MS Society.png" },
  { name: "NSPCC", icon: "icons/NSPCC.png" },
  { name: "Parkinson's UK", icon: "icons/Parkinson's UK.png" },
  { name: "RSPCA", icon: "icons/RSPCA.png" },
  { name: "Salvation Army", icon: "icons/Salvation Army.png" },
  { name: "Save the Children", icon: "icons/Save the Children.png" },
  { name: "Scope", icon: "icons/Scope.png" },
  { name: "St Lukes", icon: "icons/St Lukes.png" },
  { name: "Teenage Cancer Trust", icon: "icons/Teenage Cancer Trust.png" },
  { name: "The Aplastic Anaemia Trust", icon: "icons/The Aplastic Anaemia Trust.png" },
  { name: "The Fairtrade Foundation", icon: "icons/The Fairtrade Foundation.png" },
  { name: "The National Autistic Society", icon: "icons/The National Autistic Society.png" },
  { name: "The Scout Association", icon: "icons/The Scout Association.png" },
  { name: "Wood Green, The Animals Charity", icon: "icons/Wood Green, The Animals Charity.png" },
  { name: "Woodland Trust", icon: "icons/Woodland Trust.png" },
];

const gyms = [
  { name: "Fitness first", icon: "icons/fitnessfirst.png" },
  { name: "Nuffield Health", icon: "icons/nuffield.png" },
  { name: "JD", icon: "icons/jd.png" },
  { name: "The Gym", icon: "icons/thegym.png" },
  { name: "Puregym", icon: "icons/puregym.png" },
  { name: "Virgin Active", icon: "icons/virginactive.png" },
  { name: "Xercise4less", icon: "icons/xercise4less.png" },
];

const nationalities = [
  "British",
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape verdean",
  "Central african",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivan",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New zealander",
  "Ni-vanuatu",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North korean",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua new guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint lucian",
  "Salvadoran",
  "Samoan",
  "San marinese",
  "Sao tomean",
  "Saudi",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon islander",
  "Somali",
  "South african",
  "South korean",
  "Spanish",
  "Sri lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
];

const banks = [
  { name: "American Express", icon: "icons/banks/amex-logo.png" },
  { name: "Bank of Scotland", icon: "icons/banks/Bank of Scotland.png" },
  //{ name: "Barclays Bank", icon: "icons/banks/Barclays.png" },
  //{ name: "Barclaycard", icon: "icons/banks/Barclaycard.png" },
  { name: "Citibank", icon: "icons/banks/Citibank.png" },
  // { name: "Clydesdale", icon: "icons/banks/Clydesdale.png" },
  //{ name: "Co-Operative Bank", icon: "icons/banks/Co-Operative Bank.png" },
  // {name:'First Direct', icon:'icons/banks/firstdirect.png'},
  { name: "Halifax", icon: "icons/banks/Halifax.png" },
  //{ name: "HSBC", icon: "icons/banks/HSBC.png" },
  //{ name: "Investec", icon: "icons/banks/Investec.png" },
  //{ name: "Lloyds", icon: "icons/banks/Lloyds Bank.png" },
  //{ name: "Metro Bank", icon: "icons/banks/Metro Bank.png" },
  { name: "MBNA", icon: "icons/banks/Lloyds Bank.png" },
  { name: "Monzo", icon: "icons/banks/Monzo.png" },
  //{ name: "M&S Bank", icon: "icons/banks/M&S Bank.png" },
  //{ name: "Nationwide", icon: "icons/banks/Nationwide.png" },
  //{ name: "Natwest", icon: "icons/banks/Natwest.png" },
  // {name:'RBS', icon:'icons/banks/Royal Bank of Scotland.png'},
  { name: "Revolut", icon: "icons/banks/Revolut.png" },
  //{ name: "Sainsbury’s Bank", icon: "icons/banks/Sainsbury's Bank.png" },
  //{ name: "Santander", icon: "icons/banks/Santander.png" },
  //{ name: "Starling", icon: "icons/banks/Starling.png" },
  //{ name: "Tesco Bank", icon: "icons/banks/Tesco Bank.png" },
  //{ name: "TSB", icon: "icons/banks/TSB.png" },
  //{ name: "Virgin Money", icon: "icons/banks/Virgin Money.png" },
  //{ name: "Yorkshire Bank", icon: "icons/banks/Yorkshire Bank.png" },
  // "Bank of Scotland",
  // "Barclays Bank",
  // "Barclaycard",
  // "Citibank",
  // "Clydesdale",
  // "Co-Operative Bank",
  // // "First Direct",
  // "Halifax",
  // "HSBC",
  // "Investec",
  // "Lloyds",
  // "Metro Bank",
  // "Monzo",
  // "M&S Bank",
  // "Nationwide",
  // "Natwest",
  // //"RBS",
  // "Revolut",
  // "Sainsbury’s Bank",
  // "Santander",
  // "Starling",
  // "Tesco Bank",
  // "TSB",
  // "Virgin Money",
  // "Yorkshire Bank",
];
const gps = [""];
const pensions = [
  // { name: "Aegon", icon: "icons/aegon.png" },
  { name: "Aviva", icon: "icons/aviva.png" },
  // { name: "Barclays pension fund", icon: "icons/barclayspension.png" },
  { name: "Barclays pension fund", icon: "icons/banks/Barclays.png" },
  { name: "BP Pension", icon: "icons/bppension.png" },
  { name: "BT Pension Scheme", icon: "icons/btpension.png" },
  // { name: "Canada Life", icon: "icons/canadalife.png" },
  { name: "Equitable Life Pension", icon: "icons/banks/Lloyds Bank.png" },
  { name: "Greater Manchester Pension Fund (GMPF)", icon: "icons/gmpf.png" },
  { name: "HBOS", icon: "icons/banks/Lloyds Bank.png" },
  { name: "Interactive Investor", icon: "icons/interactiveinvestor.png" },
  //{ name: "Lloyds Pension", icon: "icons/banks/Lloyds Bank.png" },
  { name: "Legal & General", icon: "icons/legalgeneral.png" },
  { name: "National Grid UK Pension Scheme", icon: "icons/nationalgridpension.png" },
  { name: "Nest", icon: "icons/nest.png" },
  { name: "Pension Bee", icon: "icons/pensionbee.png" },
  // { name: "Scottish Widows", icon: "icons/scottishwidows.png" },
  // { name: "Standard Life", icon: "icons/standardlife.png" },
  //{ name: "Universities Superannuation Scheme", icon: "icons/universities.png" },
  { name: "RPMI Railpen", icon: "icons/rpmi.png" },
  // { name: "Royal Bank of Scottland (RBS) Pension fund", icon: "icons/bankscottlandpension.png" },
  { name: "Railway Pension Scheme", icon: "icons/rps.png" },
  { name: "Teachers' Pensions", icon: "icons/teachers-pensions.png" },
  { name: "True Potential", icon: "icons/truepotential.png" },
  { name: "Strathclyde pension fund (SPFO)", icon: "icons/strathclyde.png" },
  { name: "Your Tomorrow", icon: "icons/banks/Lloyds Bank.png" },
  // { name: "USS", icon: "icons/uss.png" },

  /* "Aegon",
  "Aviva",
  "Barclays pension fund",
  "BP Pension",
  "BT Pension Scheme",
  "Canada Life",
  "Equitable Life Pension",
  "Greater Manchester Pension Fund (GMPF)",
  "HBOS",
  "Interactive Investor",
  "Legal & General",
  // "Lloyds Pension",
  "National Grid UK Pension Scheme",
  "Nest",
  "NHS Pensions",
  "Pension Bee",
  "Railway Pension Scheme",
  // "Royal Bank of Scottland (RBS) Pension fund",
  "RPMI Railpen",
  //  "Scottish Widows",
  "Standard Life",
  "Strathclyde pension fund (SPFO)",
  "Teachers' Pensions",
  "True Potential",
  "Universities Superannuation Scheme",
  "Your Tomorrow", */
];

const titles = [
  "Mr",
  "Ms",
  "Mrs",
  "Miss",
  "Dr",
  "Professor",
  "Lady",
  "Dame",
  "Sir",
  "Judge",
  "Lord",
  "Other",
];

//Insurances
let vehicle = [
  { type: "vehicle", insurer: "AA", icon: "icons/AA.png" },
  { type: "vehicle", insurer: "Cardif Pinnacle", icon: "icons/Cardif Pinnacle.png" },
  { type: "vehicle", insurer: "RAC", icon: "icons/RAC.png" },
  { type: "vehicle", insurer: "Vitality", icon: "icons/Vitality.png" },
  { type: "vehicle", insurer: "Zurich", icon: "icons/Zurich.png" },
];

let life = [
  { type: "life", insurer: "AIG", icon: "icons/AIG.png" },
  { type: "life", insurer: "Beaglestreet", icon: "icons/Beaglestreet.png" },
  { type: "life", insurer: "BiSL", icon: "icons/BiSL.png" },
  { type: "life", insurer: "Legal and General", icon: "icons/Legal and General.png" },
  { type: "life", insurer: "Vitality", icon: "icons/Vitality.png" },
  { type: "life", insurer: "Zurich", icon: "icons/Zurich.png" },
];

let pet = [
  { type: "pet", insurer: "Admiral", icon: "icons/Admiral.png" },
  { type: "pet", insurer: "Cardif Pinnacle", icon: "icons/Cardif Pinnacle.png" },
  { type: "pet", insurer: "Legal and General", icon: "icons/Legal and General.png" },
  { type: "pet", insurer: "Agriapet", icon: "icons/Agriapet.png" },
  { type: "pet", insurer: "Morethan", icon: "icons/Morethan.png" },
  { type: "pet", insurer: "LV", icon: "icons/LV.png" },
];

let travel = [
  { type: "travel", insurer: "Allianz", icon: "icons/Allianz.png" },
  { type: "travel", insurer: "Post Office", icon: "icons/Post Office.png" },
  { type: "travel", insurer: "RAC", icon: "icons/RAC.png" },
  { type: "travel", insurer: "Zurich", icon: "icons/Zurich.png" },
  { type: "travel", insurer: "Cedar Tree", icon: "icons/Cedar Tree.png" },
  { type: "travel", insurer: "Cover For You", icon: "icons/Cover For You.png" },
  { type: "travel", insurer: "Insurance And Escape", icon: "icons/Insurance And Escape.png" },
];
//Insurances

let homecare = [
  { name: "Hometree", icon: "icons/Hometree.png" },
  { name: "HomeServe", icon: "icons/HomeServe.png" },
  { name: "EDF", icon: "icons/EDF.png" },
  { name: "247 Home Rescue", icon: "icons/247 Home Rescue.png" },
];

let breakdownCompanies = [
  { name: "AA", icon: "icons/AA.png" },
  { name: "Cover My Breakdown", icon: "icons/Cover My Breakdown.png" },
  { name: "Emergency Assist", icon: "icons/Emergency Assist.png" },
  { name: "QDOS", icon: "icons/QDOS.png" },
  { name: "RAC", icon: "icons/RAC.png" },
  { name: "Rescue My Car", icon: "icons/Rescue My Car.png" },
];

const opticians = [
  { name: "Vision Express", icon: "icons/Vision Express.png" },
  { name: "Specsavers", icon: "icons/Specsavers.png" },
  { name: "Scrivens", icon: "icons/Scrivens.png" },
  { name: "Optical Express", icon: "icons/Optical Express.png" },
  { name: "Asda Opticians", icon: "icons/Asda Opticians.png" },
];

export {
  councils,
  waterCompanies,
  loyalties,
  energySuppliers,
  charities,
  gps,
  pensions,
  gyms,
  mobileProviders,
  dentists,
  nationalities,
  banks,
  titles,
  vehicle,
  life,
  pet,
  travel,
  homecare,
  breakdownCompanies,
  opticians,
};
