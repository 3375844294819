var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-about-you animate__animated animate__fadeIn pt-4"},[(!_vm.isMobile())?_c('video',{staticClass:"img-fluid mb-4",staticStyle:{"border-radius":"120px","display":"none"},attrs:{"src":`${_vm.urlBucket}/form/assets/animation-about-you.mp4`,"autoplay":"","muted":"","loop":""},domProps:{"muted":true},on:{"loadeddata":(e) => {
        e.target.style.display = 'unset';
        _vm.hideSpinner('animation');
      }}}):_c('img',{staticClass:"w-100 mb-4",staticStyle:{"border-radius":"120px","display":"none"},attrs:{"src":`${_vm.urlBucket}/form/assets/banner-about-you.png`,"alt":""},on:{"load":(e) => {
        e.target.style.display = 'unset';
        _vm.hideSpinner('animation');
      }}}),_vm._m(0),_c('div',{staticClass:"mb-4"},[_c('h5',{staticClass:"fs-la"},[_vm._v("Title")]),_c('vSelect',{staticClass:"w-100 select-title fs-md mb-2",attrs:{"value":_vm.title,"label":"title","clearable":false,"options":_vm.titles,"placeholder":`Title`,"multiple":false},on:{"input":(e) => _vm.selectedChange('title', e)}}),(_vm.errors.title)?_c('p',{staticClass:"mb-0 fs-md",staticStyle:{"color":"#c64a45"}},[_vm._v("* This field is required")]):_vm._e()],1),_c('div',{staticClass:"mb-4"},[_c('h5',{staticClass:"fs-la"},[_vm._v("First Name")]),_c('input',{staticClass:"w-100 p-3 fs-md mb-2",attrs:{"type":"text","placeholder":"First Name"},domProps:{"value":_vm.firstName},on:{"input":(e) => _vm.selectedChange('firstName', e.target.value)}}),(_vm.errors.firstName)?_c('p',{staticClass:"mb-0 fs-md",staticStyle:{"color":"#c64a45"}},[_vm._v(" * This field is required ")]):_vm._e()]),_c('div',{staticClass:"mb-4"},[_c('h5',{staticClass:"fs-la"},[_vm._v("Last Name")]),_c('input',{staticClass:"w-100 p-3 fs-md mb-2",attrs:{"type":"text","placeholder":"Last Name"},domProps:{"value":_vm.lastName},on:{"input":(e) => _vm.selectedChange('lastName', e.target.value)}}),(_vm.errors.lastName)?_c('p',{staticClass:"mb-0 fs-md",staticStyle:{"color":"#c64a45"}},[_vm._v(" * This field is required ")]):_vm._e()]),(_vm.errors.existsUnderDifferentName)?_c('p',{staticClass:"mb-2 fs-md",staticStyle:{"color":"#c64a45"}},[_vm._v(" * The email address is currently being used for a different full name. Please use a different email address to continue ")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center my-5",attrs:{"id":"animation"}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"100px","alt":""}})])
}]

export { render, staticRenderFns }